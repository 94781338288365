import React from 'react';

const NotFound = (props) => (
    <div>
        404!
    </div>
);

export default NotFound;

