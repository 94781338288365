import React from 'react';

const Loading = () => (

    <div id="loading">
        <img src="./images/sw.gif" />
    </div>
);

export default Loading;
